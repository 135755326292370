<section class="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]">
  <div class="container">
    <div class="lg:flex lg:justify-between dark:bg-jacarta-700 rounded-2xl">
      <div class="relative lg:w-[45%]">
        <figure class="relative text-center">
          <!-- width="392"
            height="427" -->
          <img alt="image" class="relative inline-block rounded-l-xl h-full" src="assets/img/forgot.jpg"
            style="color: transparent" />
        </figure>
      </div>
      <div class="lg:w-[55%] lg:pl-24 py-16 px-8">
        <h2 class="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
          Reset Your Password
        </h2>

        <form [formGroup]="resetForm" (ngSubmit)="userResetPassword()">
          <div class="mx-auto max-w-[48.125rem]">
            <!-- Email -->
            <div class="mb-6">
              <label for="userPassword"
                class="font-display text-left text-jacarta-700 mb-2 block dark:text-white">Password<span
                  class="text-red">*</span></label>
              <input type="text" id="userPassword"
                class="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                placeholder="Enter Password" required formControlName="user_password" />
              <!-- *ngIf="resetForm.get('user_password')?.errors" -->
              <div *ngIf="
                  resetForm.get('user_password')?.invalid &&
                  (resetForm.get('user_password')?.dirty ||
                    resetForm.get('user_password')?.touched)
                " class="text-danger mt-3">
                <div *ngIf="resetForm.get('user_password')?.errors?.['required']" class="text-red text-left">
                  Password is required
                </div>
                <div *ngIf="resetForm.get('user_password')?.errors?.['pattern']" class="text-red text-left">
                  Password must contain at least one number, one uppercase and a
                  lowercase letter and at least 8 characters<br />Password
                  cannot contain whitespace
                </div>
              </div>
            </div>
            <!-- Email -->
            <div class="mb-6">
              <label for="userConfirmPassword"
                class="font-display text-left text-jacarta-700 mb-2 block dark:text-white">Confirm Password<span
                  class="text-red">*</span></label>
              <input type="text" id="userConfirmPassword"
                class="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                placeholder="Confirm Password" required formControlName="confirm_user_password" />
              <div *ngIf="
                  resetForm.get('confirm_user_password')?.invalid &&
                  (resetForm.get('confirm_user_password')?.dirty ||
                    resetForm.get('confirm_user_password')?.touched)
                " class="form-text text-danger">
                <div *ngIf="resetForm.get('confirm_user_password')?.errors?.['required']" class="text-red text-left">
                  Field is required
                </div>
                <div *ngIf="resetForm.get('confirm_user_password')?.errors?.['pattern']" class="text-red text-left">
                  Password must contain at least one number, one uppercase and a
                  lowercase letter and at least 8 characters<br />Password
                  cannot contain whitespace
                </div>
                <div *ngIf="resetForm.get('confirm_user_password')?.errors?.['ConfirmPasswordValidator']"
                  class="text-red text-left">
                  Passwords did not match
                </div>
              </div>
            </div>
            <!-- Submit -->
            <button type="submit" [disabled]="resetForm.invalid"
              class="bg-accent w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>