import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-house-interiors',
  templateUrl: './house-interiors.component.html',
  styles: [],
})
export class HouseInteriorsComponent implements OnInit {
  textures = [
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/texture-1.png',
      thumbanil:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/thumbnail-1.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/texture-2.png',
      thumbanil:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/thumbnail-2.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/texture-3.png',
      thumbanil:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/house_interiors/thumbnail-3.png',
    },
  ];
  ngOnInit(): void {}
  @ViewChild('modelViewer', { static: false }) modelViewer!: ElementRef;

  onModelLoad(event: Event) {
    const modelViewerElement = this.modelViewer.nativeElement;
    const material = modelViewerElement.model.materials[0];
    if (!modelViewerElement.model || !modelViewerElement.model.materials) {
      console.error('Model or materials not available.');
      return;
    }

    if (!material) {
      console.error('No materials found on the model.');
      return;
    }
    const createAndApplyTexture = async (channel: any, texturePath: any) => {
      const texture = await modelViewerElement.createTexture(texturePath);
      if (texturePath === 'None') {
        material[channel]?.setTexture();
      } else {
        if (
          channel.includes('baseColorTexture') ||
          channel.includes('metallic')
        ) {
          material.pbrMetallicRoughness[channel].setTexture(texture);
        } else {
          material[channel].setTexture(texture);
        }
      }
    };

    this.createAndApplyTexture = createAndApplyTexture; // Save the function for later use
  }
  createAndApplyTexture(channel: any, texturePath: string): void {
    // Placeholder for the function that will be assigned later
  }
  onTextureChange(url: any) {
    // const selectElement = event.target as HTMLSelectElement;
    const texturePath = url;
    // console.log(texturePath);
    this.createAndApplyTexture('baseColorTexture', texturePath);
  }
}
