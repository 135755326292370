import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmPasswordValidator } from '../shared/validators/confirm-password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [],
})
export class ResetPasswordComponent {
  resetForm!: FormGroup;

  user_email: any;
  user_code: any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,

    private router: Router
  ) {
    this.userResetForm();
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.user_email = params['user_email'];
      this.user_code = params['user_code'];
    });
  }

  userResetForm() {
    this.resetForm = this.formBuilder.group(
      {
        user_email: [''],
        user_code: [''],
        user_password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
            ),
          ],
        ],
        confirm_user_password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
            ),
          ],
        ],
      },
      {
        validator: ConfirmPasswordValidator(
          'user_password',
          'confirm_user_password'
        ),
      }
    );
  }

  get valid() {
    return this.resetForm.controls;
  }

  // ========== Reset Form Function Start =================
  reset_data: any;
  error_reset_data: any;
  userResetPassword() {
    // let user_email = Object.assign(this.resetForm.value, {
    //   user_email: this.user_email,
    // });
    // let user_code = Object.assign(this.resetForm.value, {
    //   user_code: this.user_code,
    // });
    // this.authService.userResetPassword(this.resetForm.value).subscribe({
    //   next: (val: any) => {
    //     this.reset_data = val;
    //   },
    //   error: (err: any) => {
    //     this.error_reset_data = err;
    //     this.toastr.error(this.error_reset_data.error.message, 'Error');
    //   },
    //   complete: () => {
    //     if (this.reset_data.status == 1) {
    //       this.toastr.success(this.reset_data.message, 'Success');
    //       setTimeout(() => {
    //         this.router.navigate(['login']);
    //       }, 1000);
    //     } else {
    //       this.toastr.warning(this.reset_data.message, 'Info');
    //     }
    //   },
    // });
  }
  // ========== Reset Form Function End =================
}
