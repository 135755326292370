<div class="page-wrapper">
  <div class="ar-container">
    <a-scene mindar-image="imageTargetSrc: assets/aframe/drinks/drinks.mind;autoStart: true" color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false">
      <a-assets>
        <a-asset-item id="video" muted crossorigin="anonymous" src="assets/aframe/drinks/coke.mp4"></a-asset-item>

        <a-asset-item id="text" crossorigin="anonymous"
          src="https://isl-wisharpro-public.s3.ap-south-1.amazonaws.com/assets/default_postar_image/postar.jpg"></a-asset-item>

        <!-- <a-asset-item id="model" src="assets/glb/Cococola_Tin.glb"></a-asset-item> -->
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

      <a-entity mindar-image-target="targetIndex: 0">
        <a-entity geometry="primitive:box; width:1; height:1" position="0 0 0" rotation="0 0 0"
          material="src:#video;"></a-entity>

        <!-- <a-entity geometry="primitive:sphere; width:2; height:2" position="0 0 0" rotation="0 0 0"
          material="src:#video;"></a-entity> -->

        <a-entity id="video-cube" gltf-model="#model" scale="0.1 0.1 0.1" position="0 1 0"
          video-texture="src: #text"></a-entity>

        <a-sky color="#000000"></a-sky>
      </a-entity>
    </a-scene>
  </div>

  <!-- <div class="ar-container">
    <a-scene mindar-image="imageTargetSrc: assets/aframe/drinks/drinks.mind; showStats: false; autoStart: true;"
      embedded color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false">
      <a-assets>
        <img crossorigin="anonymous" id="skype" src="assets/aframe/drinks/skype.png" />
        <img crossorigin="anonymous" id="twitter" src="assets/aframe/drinks/twitter_hexa.png" />
        <img crossorigin="anonymous" id="whatsapp" src="assets/aframe/drinks/Whatsapp.png" />

        <img crossorigin="anonymous" id="cocacola-logo" src="assets/aframe/drinks/cocacola-logo.png" />

        <img crossorigin="anonymous" id="play" src="assets/aframe/drinks/play.png" />

        <video crossorigin="anonymous" id="sula" src="assets/aframe/drinks/sula.mp4" muted></video>
        <video crossorigin="anonymous" id="coca-cola" src="assets/aframe/drinks/coke.mp4" muted></video>
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;"
        raycaster="far: 10000; objects: .clickable"></a-camera>

      <a-entity id="ar-drinks-sulatarget" ar-drinks-sulatarget data-comp="sula" mindar-image-target="targetIndex: 0">
        <a-video id="sula-video-link" webkit-playsinline playsinline width="1" height="0.5" position="0 0 0">
        </a-video>

        <a-image class="clickable" src="#whatsapp" geometry=" height:0.2; width: 0.2;"
          position="-0.45 -0.7 0"></a-image>
        <a-image class="clickable" src="#skype" geometry="height: 0.2; width:0.2;" position="0 -0.7 0"></a-image>
        <a-image class="clickable" src="#twitter" geometry=" height: 0.2; width:0.2;" position="0.45 -0.7 0"></a-image>
      </a-entity>

      <a-entity id="ar-drinks-coketarget" ar-drinks-coketarget mindar-image-target="targetIndex: 1">
        <a-video id="coke-video-link" webkit-playsinline playsinline width="1" height="0.5" position="0 0 0">
        </a-video>

        <a-image class="clickable" src="#whatsapp" geometry=" height:0.2; width: 0.2;"
          position="-0.45 -0.7 0"></a-image>
        <a-image class="clickable" src="#skype" geometry="height: 0.2; width:0.2;" position="0 -0.7 0"></a-image>
        <a-image class="clickable" src="#twitter" geometry=" height: 0.2; width:0.2;" position="0.45 -0.7 0"></a-image>
      </a-entity>
    </a-scene>
  </div> -->

  <span class="play_icon" style="position: absolute; right: 0; bottom: 0">
    <img crossorigin="anonymous" class="responsive mimg" style="width: 70px" src="assets/aframe/drinks/play.png"
      id="play" onclick="playVideo()" />
  </span>

  <!-- <span class="sound_icon">
    <img crossorigin="anonymous" class="responsive mimg" src="assets/aframe/drinks/mute_1.png" id="sound"
      onclick="toggleMute()" />
  </span> -->
</div>