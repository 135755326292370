import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pizza',
  templateUrl: './pizza.component.html',
  styleUrls: ['./pizza.component.css']
})
export class PizzaComponent {
  @ViewChild('pizza', { static: false }) pizza!: ElementRef;
  ngOnInit(): void { }
  onModelLoad(event: Event) {
    const modelViewerElement = this.pizza.nativeElement;
    const material = modelViewerElement.model.materials[0];
    if (!modelViewerElement.model || !modelViewerElement.model.materials) {
      console.error('Model or materials not available.');
      return;
    }

    if (!material) {
      console.error('No materials found on the model.');
      return;
    }
    // Save the function for later use
  }
}
