<section class="relative pt-24 pb-24 lg:py-24">
    <div class="px-6 xl:px-24">
      <div class="md:flex md:flex-wrap">
        <!-- Image -->
        <figure class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2">
          <model-viewer #pizza
            src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/pizza/Pizza_Animation1.glb"
            poster="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/pizza/Pizza.png" tone-mapping="neutral" shadow-intensity="1" ar
            ar-modes="webxr scene-viewer quick-look" camera-controls autoplay touch-action="pan-y"
            alt="A 3D model carousel" (load)="onModelLoad($event)"></model-viewer>
        </figure>
  
        <!-- Details -->
        <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
          <!-- Collection / Likes / Actions -->
          <div class="mb-3 flex">
            <!-- Collection -->
  
            <!-- Likes / Actions -->
            <div class="ml-auto flex space-x-2">
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4">
                <span
                  class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  data-tippy-content="Favorite">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                    <path fill="none" d="M0 0H24V24H0z"></path>
                    <path
                      d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                    </path>
                  </svg>
                </span>
                <span class="dark:text-jacarta-200 text-sm">188</span>
              </div>
            </div>
          </div>
  
          <h1 class="font-display text-jacarta-700 mb-4 text-4xl font-semibold dark:text-white">
            Pizza
          </h1>
  
          <p class="dark:text-jacarta-300 mb-10">
            Augmented Reality (AR) is a rapidly evolving technology that overlays
            digital information onto the real world, and it’s making a significant
            impact in the home appliances sector.
          </p>
  
          <!-- Bid -->
          <div class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-8">
            <ol type="1">
              <li class="dark:text-jacarta-300 mb-10">
                <b>Immersive Product Visualization:</b> AR allows customers to
                visualize home appliances in a highly immersive manner. Customers
                can virtually place items in their living spaces, allowing them to
                assess how products fit and complement their existing décor
              </li>
              <li class="dark:text-jacarta-300 mb-10">
                <b>Accurate Size and Scale Representation:</b> AR provides
                consumers with the ability to view digital models of products in
                real-time, offering accurate size and scale representations. This
                empowers customers to make informed decisions, reducing the
                likelihood of purchasing items that do not align with their
                specific requirements or spatial limitations
              </li>
              <li class="dark:text-jacarta-300 mb-10">
                <b>Personalized Customization:</b> With AR, consumers can tailor
                their products to suit their preferences2. They can experiment
                with different colors, finishes, and styles for smartphones or
                select the ideal settings and configurations for home appliances
              </li>
            </ol>
          </div>
          <!-- end bid -->
        </div>
        <!-- end details -->
      </div>
    </div>
  </section>