import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestingRoutingModule } from './testing-routing.module';

import { SpheareVideoComponent } from './spheare-video/spheare-video.component';
import { CarMultipleComponent } from './car-multiple/car-multiple.component';
import { HomeStoreComponent } from './home-store/home-store.component';
import { OfficeSpaceComponent } from './office-space/office-space.component';
import { HouseInteriorsComponent } from './house-interiors/house-interiors.component';
import { BurgerComponent } from './burger/burger.component';
import { PizzaComponent } from './pizza/pizza.component';

@NgModule({
  declarations: [
    SpheareVideoComponent,
    CarMultipleComponent,
    HomeStoreComponent,
    OfficeSpaceComponent,
    HouseInteriorsComponent,
    BurgerComponent,
    PizzaComponent,
  ],
  imports: [CommonModule, TestingRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TestingModule { }
