import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})
export class ForgotPasswordComponent {
  forgotForm!: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    this.userForgotForm();
  }

  userForgotForm() {
    this.forgotForm = this.formBuilder.group({
      user_email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  get valid() {
    return this.forgotForm.controls;
  }
  // ========== Forgot Password Function Start=================
  forgotPasswordJson: any;
  // userForgotPassword() {
  //   this.authService.userforgotPassword(this.forgotForm.value).subscribe({
  //     next: (val: any) => {
  //       // console.log(val);
  //       this.forgotPasswordJson = val;
  //     },
  //     error: (err: any) => {
  //       console.log(err);
  //       this.toastr.error('Error', err.message);
  //     },
  //     complete: () => {
  //       if (this.forgotPasswordJson.status === 1) {
  //         this.toastr.success(this.forgotPasswordJson.message, 'Success');
  //         this.forgotForm.reset();
  //       } else {
  //         this.toastr.warning(this.forgotPasswordJson.message, 'Warning');
  //         this.forgotForm.reset();
  //       }
  //     },
  //   });
  // }
  // ========== Forgot Password Function End =================
}
