<section class="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]">
  <div class="container">
    <div class="lg:flex lg:justify-between dark:bg-jacarta-700 rounded-2xl">
      <div class="relative lg:w-[45%]">
        <figure class="relative text-center">
          <!-- width="392"
            height="427" -->
          <img alt="image" class="relative inline-block rounded-l-xl" src="assets/img/forgot.jpg"
            style="color: transparent" />
        </figure>
      </div>
      <div class="lg:w-[55%] lg:pl-24 py-16 px-8">
        <h2 class="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
          Forgot Your Password
        </h2>

        <form [formGroup]="forgotForm">
          <div class="mx-auto max-w-[48.125rem]">
            <div class="mb-6">
              <label for="userEmail"
                class="font-display text-left text-jacarta-700 mb-2 block dark:text-white">Email<span
                  class="text-red">*</span></label>
              <input type="text" id="userEmail"
                class="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                placeholder="Enter Email Id" required formControlName="user_email" />
              <div *ngIf="
                  valid['user_email'].touched && valid['user_email'].invalid
                " class="mt-3">
                <div *ngIf="valid['user_email'].errors?.['required']" class="dark:text-red text-red text-left text-sm">
                  Email is required
                </div>
                <div class="dark:text-red text-red text-left text-sm"
                  *ngIf="valid['user_email'].errors?.['required'] && valid['user_email'].dirty">
                  Please enter email address
                </div>
                <div class="dark:text-red text-red text-left text-sm"
                  *ngIf="valid['user_email'].errors?.['email'] && valid['user_email'].dirty">
                  Please enter a valid email address
                </div>
              </div>
            </div>
            <button type="submit" [disabled]="forgotForm.invalid"
              class="bg-accent w-full cursor-default rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
              Send Email
            </button>
            <div class="text-center mt-3">
              <p class="dark:text-white">
                Already have an account?
                <a routerLink="/login" class="text-sm leading-normal mb-6 text-accent">Login</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>