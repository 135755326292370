import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.css']
})
export class BurgerComponent {
  @ViewChild('burger', { static: false }) burger!: ElementRef;
  ngOnInit(): void { }
  onModelLoad(event: Event) {
    const modelViewerElement = this.burger.nativeElement;
    const material = modelViewerElement.model.materials[0];
    if (!modelViewerElement.model || !modelViewerElement.model.materials) {
      console.error('Model or materials not available.');
      return;
    }

    if (!material) {
      console.error('No materials found on the model.');
      return;
    }
    // Save the function for later use
  }
}
